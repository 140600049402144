.wizard_title{
    font-size:18px;
    font-weight:bold;
    color:#165486;
}
.Mui-completed{
    color: green !important;
}
.Mui-active {
    color: #f64f64 !important;
}
.makeStyles-dropzone-1{
    padding:0px;
}
.MuiBox-root label{
    font-weight: bold;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7) !important;
}
.custom_input{
    border:1px solid #c4c4c4;
    border-radius:5px;
    height:55px;
    position: relative;
    padding-left: 10px;
    padding-top: 13px;
}
.custom_input label{
    position: absolute;
    left:10px;
    top:-10px;
    font-size: 10px;
    background-color: #ffffff;
    padding-right: 20px;
}
.css-1wvihky-MuiTypography-root{
    width:100% !important;
    font-size: 13px !important;
}
.control_disabled{
    pointer-events: none !important;
    z-index: 0;
    position: relative;
    cursor: no-drop;
}
.MuiButton-root{
    height:45px;
}
.isOwnProperty{
    position: relative !important;
}
.isOwnProperty .Mui-error{
    position: absolute !important;
    top: 75px !important;
    left:0px !important;
}
.isCustomField{
    position: relative !important;
}
.isCustomField p.Mui-error{
    position: absolute;
    top: 100px !important;
    left:0px !important;
}
.isCustomField label{
    position: relative !important;
    top:0px !important;
}
.css-lwut9l-MuiGrid2-root{
    margin: 0px !important;
}
.isCustomField span{
    font-size: 10px;
    margin-top: -4px;
    position: absolute;
    display: block;
}
.irt .css-lwut9l-MuiGrid2-root{
    margin-top: 20px !important;
}